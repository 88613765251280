<template>
    <aboutComponent
          data-aos="fade-up" data-aos-duration="2000"
    />
    <faqs
          data-aos="fade-up" data-aos-duration="2000"
    />


</template>

<script>
import aboutComponent from "@/components/layout/aboutComponent.vue";
import faqs from "@/components/layout/faqsComponent.vue"
import AOS from "aos";

export default{
  mounted() {
    AOS.init();
  },
  components:{
aboutComponent,
faqs
  }
}
</script>
