<template>
  <footer class="footer">
    <div class="container">
      <div class="footer-column">
        <h3>About Our Company</h3>
          <ul>
          <li v-for="link in aboutLinks" :key="link.text" class="useful d-flex align-items-center mb-2">
            <i class="fa-solid fa-angles-right"></i>
            <router-link :to="link.href" class="use-link text-start d-inline-block mx-3">
                {{ link.text }}
                </router-link>
          </li>
        </ul>      
        </div>
      <div class="footer-column">
        <h3>Latest Cars</h3>
        <div v-for="car in latestCars" :key="car.id" >
          <router-link :to="'/car/'+car.id" class="car">
            <img :src="car.image" alt="Car Image" height="100">
          <div class="car-info">
            <h4 class="car-title">{{ car.brand }} {{ car.model }} </h4>
            <p class="text-start mb-0">Car ID :<span style="color:#fff">{{ car.car_num }}</span></p>
            <p class="text-start mb-0"> Price : {{ car.price }}</p>
            <p class="text-start mb-0"> VIN : {{car.vin }}</p>
          </div>
          </router-link>
        </div>
      </div>
      <div class="footer-column">
        <h3>Useful Links</h3>
        <ul>
          <li v-for="link in usefulLinks" :key="link.text" class="useful d-flex align-items-center mb-2">
            <i class="fa-solid fa-angles-right"></i>
            <router-link :to="link.href" class="use-link text-start d-inline-block mx-3">
                {{ link.text }}
                </router-link>
          </li>
        </ul>
      </div>
      <div class="footer-column">
        <h3>Contact Information</h3>
        <div>
          <div class="d-flex align-items-center mb-3">
            <font-awesome-icon class="footer-icon" icon="location-dot" /> 
            <span class="mx-3">
                <div class="text-start" style="color:#919191">
                    Address
                </div>
                <div class="footer-title text-start">
                  <a target="_blank" :href="data.location_url"> {{data.intro_address}} </a>
                </div>
            </span>
          </div>
          <div class="d-flex align-items-center mb-3">
            <font-awesome-icon class="footer-icon" icon="phone" /> 
        <span class="mx-3">
                <div class="text-start" style="color:#919191">
                    Phone
                </div>
                <div class="footer-title text-start">
                    <a target="_blank" :href="'tel:'+data.intro_phone"> {{ data.intro_phone }} </a>
                </div>
            </span>
          </div>
         <div class="d-flex align-items-center mb-3">
             <font-awesome-icon class="footer-icon" icon="envelope" /> 
          <span class="mx-3">
                <div class="text-start" style="color:#919191">
                    Email
                </div>
                <div class="footer-title text-start">
                                      <a target="_blank" :href="'tel:'+data.email"> {{ data.email }} </a>

                </div>
            </span>
         </div>
         <div class="d-flex align-items-center mb-3">
             <!-- <font-awesome-icon  class="" :icon="['fab', 'instagram']" /> -->
             <i class="fa-brands fa-whatsapp footer-icon"></i>
             <span class="mx-3">
                <div class="text-start" style="color:#919191">
                    WhatsApp
                </div>
                <div class="footer-title text-start">
                  <a  :href="'https://wa.me/' + data.whatsapp_country_code+data.whatsapp" target="_blank">                  {{ data.whatsapp_country_code }}{{ data.whatsapp }}    </a>
            
                  </div>
            </span>
         </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom d-flex justify-content-around">
      <p>&copy; Copyright 2024. All Rights Reserved - Noor Al Wahda</p>
      <p>Powered by Noor Al Wahda</p>
    </div>
  </footer>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      data : {},
      latestCars: [
        
      ],
      usefulLinks: [
        { text: 'Available Car', href: '#' },
        { text: 'Shipping Price List', href: 'shipping-lists' },
        { text: 'Search by Vin or LOT', href: 'search' },
        { text: 'Buy Cars', href: '#' },
        { text: 'Ledger', href: 'ledger' },
        { text: 'My Cars', href: 'mycars' },
        { text: 'Subscribe', href: '#' }
      ],
      aboutLinks: [
        { text: 'About Us', href: 'about' },
        { text: 'Contact Us', href: 'contact' },
        { text: 'Privacy Policy', href: 'privacy' },
        { text: 'Terms & Conditions', href: 'terms' },
       
      ],
    };
  },
  methods:{
    async getSettings(){
      await axios.get('settings')
      .then( (res)=>{
        this.data = res.data.data
      } )
    },
    async getCars(){
      await axios.get('available-cars')
      .then( (res)=>{
        this.latestCars = res.data.data.data ;
      } )
    }
  },
  mounted(){
    this.getSettings()
    this.getCars()
  }
};
</script>

<style scoped>
.footer-bottom{
    background: #331F8E;
}

.car-title{
    color: #FFF;

font-size: 15px;
font-style: normal;
font-weight: 700;
line-height: 21px; /* 140% */

}
.footer-title{
    color: #FFF;

font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 200% */

}
.footer-icon{
    border-radius:  4px;
    color: #331F8E;
    padding: 17px 16px;
    font-size: 20px;
opacity: 0.72;
background:  #FFF;


}
.fa-angles-right{
    color:#606060 ;
}
.use-link{
    color: rgba(255, 255, 255, 0.72);

font-family: Poppins;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 200% */

}
.useful a{
    display: inline-block;
    text-align: start;
    width: 100%;
}
.footer-desc{
    color: rgba(255, 255, 255, 0.75);

font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 32px; /* 200% */

}
.footer-column h3{
    border-bottom: 2.5px solid #331F8E;
    padding-bottom: 12px;
    text-align: start;
    margin-bottom: 20px !important;
}
.footer {
  background-color: #1e1e1e;
  color: #fff;
  padding: 40px 0 0px 0px;
  font-size: 14px;
}

.container {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-column {
  flex: 1;
  margin: 0 20px;
}

.footer-column h3 {
  font-size: 16px;
  margin-bottom: 10px;
}

.footer-column p, .footer-column ul, .footer-column a {
  font-size: 14px;
  color: #ddd;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 5px;
}

.footer-column ul li a {
  text-decoration: none;
  color: #ddd;
}

.footer-column ul li a:hover {
  text-decoration: underline;
}

.footer-column .car {
  display: flex;
  margin-bottom: 10px;
}

.footer-column .car img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-right: 10px;
}
.car{
      border-bottom: 2px solid rgba(167, 167, 167, 0.20);

}
.footer-column .car-info h4 {
  font-size: 14px;
  margin: 0;
  color: #fff;


}

.footer-column .car-info p {
  margin: 5px 0 0 0;
}

.footer-bottom {
  background-color: #331F8E;
  color: #ddd;
  text-align: center;
  padding: 10px 0;
  margin-top: 20px;
}

.footer-bottom p {
  margin: 5px 0;
    color: #FFF;

font-family: Poppins;
font-size: 14px !important;
font-style: normal;
font-weight: 400;
line-height: 28px; /* 200% */
}

.footer-bottom p:first-child {
  font-size: 12px;
}

.footer-bottom p:last-child {
  font-size: 14px;
}
</style>
